@import"https://unpkg.com/open-props/easings.min.css";
.sun-and-moon>:is(.moon, .sun, .sun-beams) {
    transform-origin: center center
}
.sun-and-moon>:is(.moon, .sun) {
    fill: var(--icon-fill)
}
.theme-toggle:is(:hover, :focus-visible)>.sun-and-moon>:is(.moon, .sun) {
    fill: var(--icon-fill-hover)
}
.sun-and-moon>.sun-beams {
    stroke: var(--icon-fill);
    stroke-width: 2px
}
.theme-toggle:is(:hover, :focus-visible) .sun-and-moon>.sun-beams {
    stroke: var(--icon-fill-hover)
}
.dark .sun-and-moon>.sun {
    transform: scale(1.75)
}
.dark .sun-and-moon>.sun-beams {
    opacity: 0
}
.dark .sun-and-moon>.moon>circle {
    transform: translate(-7px)
}
@supports (cx: 1) {
    .dark .sun-and-moon>.moon>circle {
        transform: translate(0);
        cx: 17
    }
}
@media (prefers-reduced-motion: no-preference) {
    .sun-and-moon>.sun {
        transition: transform .5s var(--ease-elastic-3)
    }
    .sun-and-moon>.sun-beams {
        transition: transform .5s var(--ease-elastic-4), opacity .5s var(--ease-3)
    }
    .sun-and-moon .moon>circle {
        transition: transform .25s var(--ease-out-5)
    }
    @supports (cx: 1) {
        .sun-and-moon .moon>circle {
            transition: cx .25s var(--ease-out-5)
        }
    }
    .dark .sun-and-moon>.sun {
        transform: scale(1.75);
        transition-timing-function: var(--ease-3);
        transition-duration: .25s
    }
    .dark .sun-and-moon>.sun-beams {
        transform: rotate(-25deg);
        transition-duration: .15s
    }
    .dark .sun-and-moon>.moon>circle {
        transition-delay: .25s;
        transition-duration: .5s
    }
}
.theme-toggle {
    --size: 2rem;
    --icon-fill: hsl(210 10% 30%);
    --icon-fill-hover: hsl(210 10% 15%);
    background: none;
    border: none;
    padding: 0;
    inline-size: var(--size);
    block-size: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline-offset: 5px
}
.theme-toggle>svg {
    inline-size: 100%;
    block-size: 100%;
    stroke-linecap: round
}
.dark .theme-toggle {
    --icon-fill: hsl(210 10% 70%);
    --icon-fill-hover: hsl(210 15% 90%)
}
@media (hover: none) {
    .theme-toggle {
        --size: 48px
    }
}


@-webkit-keyframes octocat-wave {
    0%, to {
        transform: rotate(0)
    }
    20%, 60% {
        transform: rotate(-25deg)
    }
    40%, 80% {
        transform: rotate(10deg)
    }
}
@keyframes octocat-wave {
    0%, to {
        transform: rotate(0)
    }
    20%, 60% {
        transform: rotate(-25deg)
    }
    40%, 80% {
        transform: rotate(10deg)
    }
}
