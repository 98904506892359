/* Reset */
@import 'tailwindcss/base';
@import 'reset';
@import 'browser_default';


/* Components */
@import 'tailwindcss/components';
@import 'buttons';
@import 'form';
@import 'font';

/** Typography */
//@import "";

/* Utilities */
@import 'tailwindcss/utilities';
@import 'tailwindcss/screens';

/** Landing Site */
@import "landing/custom.scss";
//@import 'landing/_buttons';
//@import 'landing/_choices';
//@import 'landing/_contact';
//@import 'landing/_footer';
//@import 'landing/_general';
//@import 'landing/_helper';
//@import 'landing/_hero';
//@import 'landing/_portfolio';
//@import 'landing/_topnav';

/* Modules */
@import "variables";
@import "switch";
@import "menu";
@import "tables";
@import "chat";
@import "dashboard";
@import 'custom';
@import 'dark_screen';
@import 'mediaQuery';
@import "color_scheme.scss";
